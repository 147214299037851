<template>
  <div>
    <el-table
      :data="tableData"
      row-key="id"
      :height="height"
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <slot />
    </el-table>
    <el-pagination
      v-if="isPaging"
      style="text-align: right; margin-top: 20px"
      :total="total"
      background
      :page-size="pageSize"
      :page-sizes="[10, 20, 50]"
      :current-page.sync="currentPage"
      layout="total, sizes, prev, pager, next, jumper"
      @current-change="handlePageChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    pageSize: {
      type: Number,
      default: 20,
    },
    height: {
      type: [Number, String],
    },
    rowKey: String,
    autoLoad: {
      type: Boolean,
      default: true,
    },
    api: {
      type: Function,
    },
    apiParams: {
      type: [Object, Array],
    },
    isPaging: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      tableData: [],
      currentPage: 1,
    };
  },
  mounted() {
    if (this.api && this.autoLoad) {
      this.onLoad();
    }
  },
  methods: {
    handleSelectionChange(selections) {
      this.$emit('selection-change', selections);
    },
    handlePageChange(page) {
      this.onLoad(page);
      this.$emit('page-change');
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.onLoad();
    },
    onLoad(page = 1) {
      // this.loading = true;
      this.api({
        currentPage: page,
        pageSize: this.pageSize,
        ...this.apiParams,
      })
        .then(res => {
          if (res.data && res.data.records) {
            this.tableData = res.data.records;
            this.total = res.data.totalSize;
            this.currentPage = res.data.currentPage;
          } else if (res.records) {
            this.tableData = res.records;
            this.total = res.totalSize;
            this.currentPage = res.currentPage;
          } else {
            // 不分页的情况
            this.tableData = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeItemByIndex(index) {
      this.tableData.splice(index, 1);
    },
    updateItemByIndex(index, fn) {
      fn(this.tableData[index]);
    },
  },
};
</script>

<style></style>
