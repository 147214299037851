<template>
  <main-content>
    <el-container class="permissions_user__list">
      <el-aside>
        <h1>组织架构</h1>
        <el-tree
          :expand-on-click-node="false"
          default-expand-all
          highlight-current
          :data="deptList"
          node-key="id"
          @node-click="handleNodeClick"
          :props="defaultProps"
        />
      </el-aside>
      <el-main>
        <el-form :inline="true">
          <el-form-item label="用户名称">
            <el-input
              v-model="apiParams.userName"
              clearable
              v-on:keyup.enter="handleSearch"
              placeholder="请输入用户名称"
            />
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="apiParams.status">
              <el-option
                v-for="item in statusOptions"
                :label="item.name"
                :key="item.value"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-form>
        <div class="mb-20">
          <el-button type="primary" @click="handleCreate">新建</el-button>

          <el-button
            :disabled="selectionRows.length === 0"
            @click="handleBatchChageStatus"
          >
            批量停用
          </el-button>
        </div>
        <pagination-table
          ref="pageTable"
          :autoLoad="false"
          :api="getUserListByPage"
          :apiParams="apiParams"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" />
          <el-table-column label="序号" type="index" />
          <el-table-column label="用户名称" prop="userName" />
          <el-table-column label="手机号" prop="mobile" />
          <el-table-column label="账号类型" prop="userTypeName" />
          <el-table-column label="客户/供应商" prop="companyName" />
          <el-table-column label="状态" prop="status">
            <template slot-scope="scope">
              <span :class="'status_' + scope.row.status">
                已{{ STATUS_TEXT[scope.row.status] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="160" align="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleEdit(scope)">编辑</el-button>
              <router-link
                :to="`/permissions/user/permission/user/${scope.row.id}`"
              >
                <el-button type="text" style="margin: 0 10px"> 权限 </el-button>
              </router-link>
              <el-popconfirm
                title="你确定停用该账户吗?"
                v-if="scope.row.status === STATUS.NORMAL"
                @confirm="handleChageStatus(scope, STATUS.STOP)"
              >
                <el-button
                  type="text"
                  :class="'status_' + STATUS.STOP"
                  slot="reference"
                >
                  停用
                </el-button>
              </el-popconfirm>
              <el-popconfirm
                v-if="scope.row.status === STATUS.STOP"
                title="你确定启用该账户吗?"
                @confirm="handleChageStatus(scope, STATUS.NORMAL)"
              >
                <el-button
                  type="text"
                  :class="'status_' + STATUS.NORMAL"
                  slot="reference"
                >
                  启用
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </pagination-table>
      </el-main>
    </el-container>
    <el-dialog
      :title="formData.id ? '编辑用户' : '添加用户'"
      :visible.sync="visible"
      :append-to-body="true"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <el-form label-width="100px" ref="form" :model="formData">
        <el-row v-if="isSysAdminAccount">
          <el-col>
            <el-form-item label="账户类型" required>
              <el-radio-group
                :disabled="formData.id != null"
                v-model="formData.userType"
                @change="handleUserTypeChange"
              >
                <el-radio
                  v-for="item in userTypeOptions"
                  :label="item.value"
                  :key="item.value"
                >
                  {{ item.name }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col v-if="formData.userType !== USER_TYPE.SYS_MANAGER" :span="12">
            <el-form-item
              :label="companyLabel"
              prop="companyId"
              :rules="[{ required: true, message: '请选择' + companyLabel }]"
            >
              <el-select
                :disabled="formData.id != null"
                v-model="formData.companyId"
                style="width: 100%"
                @change="handleCompanyChange"
              >
                <el-option
                  v-for="item in companyOptions"
                  :label="item.customerName || item.companyName"
                  :key="item.id"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="用户姓名"
              prop="userName"
              :rules="[
                { required: true, message: '用户名称不能为空' },
                { pattern: /[^\s]/, message: '不能以空格开头' },
              ]"
            >
              <el-input
                v-model="formData.userName"
                maxlength="30"
                placeholder="请输入用户姓名"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="手机号"
              prop="mobile"
              :rules="[
                { required: true, message: '手机号不能为空' },
                {
                  pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
                  message: '请输入正确的手机号',
                },
                {
                  validator: (rule, mobile, callback) => {
                    if (formData.id && mobile === phone) {
                      callback();
                      return;
                    }
                    checkMobileIsUnique(mobile).then(res => {
                      if (!res.data) {
                        callback('手机号已被使用');
                      } else {
                        callback();
                      }
                    });
                  },
                },
              ]"
            >
              <el-input
                v-model="formData.mobile"
                maxlength="11"
                placeholder="请输入手机号"
              />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="性别" prop="sex" required>
              <el-radio-group v-model="formData.sex">
                <el-radio label="0">男</el-radio>
                <el-radio label="1">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属" prop="deptId">
              <el-cascader
                :key="Math.random()"
                v-model="formData.deptId"
                :options="deptOptions"
                :props="{
                  checkStrictly: true,
                  emitPath: false,
                  label: 'name',
                  value: 'id',
                }"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
          <el-col v-if="isSysAdminAccount" :span="12">
            <el-form-item label="角色" prop="roleIds">
              <el-select
                v-model="formData.roleIds"
                multiple
                clearable
                style="width: 100%"
              >
                <el-option
                  v-for="item in roleList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="handleCancel">取消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          @click="handleSubmit('form')"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </main-content>
</template>

<script>
import PaginationTable from '@/components/PaginationTable.vue';
import MainContent from '@/components/MainContent.vue';
import { deptApi, userApi, customerApi, roleApi, supplierApi } from '@/api';
import { assign, formatTree } from '@/utils/tools';
import {
  USER_TYPE,
  STATUS,
  STATUS_TEXT,
  USER_TYPE_TEXT,
} from '@/constants/user-status';
const initialForm = {
  id: null,
  userType: '',
  userName: '',
  companyId: null,
  companyName: null,
  mobile: '',
  sex: '0',
  roleIds: [],
  deptId: null,
};
export default {
  components: { PaginationTable, MainContent },
  data() {
    return {
      loading: false,
      STATUS,
      STATUS_TEXT,
      USER_TYPE,
      visible: false,
      deptList: [],
      deptOptions: [],
      roleList: [],
      companyOptions: [],
      partnerList: [],
      supplierList: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      apiParams: {
        deptId: '',
        userName: '',
        status: STATUS.NORMAL,
      },
      selectionRows: [],
      // 手机号副本（用于比较编辑时手机号是否修改过）
      phone: '',
      formData: { ...initialForm },
    };
  },
  computed: {
    userTypeOptions() {
      return Object.keys(USER_TYPE_TEXT).map(key => ({
        name: USER_TYPE_TEXT[key],
        value: key,
      }));
    },
    // 获取当前账号登录的类型
    getLoginUserType() {
      return this.$store.state.userInfo.userType;
    },
    statusOptions() {
      const list = Object.keys(STATUS_TEXT).map(key => ({
        name: STATUS_TEXT[key],
        value: key,
      }));
      list.unshift({ name: '不限', value: '' });
      return list;
    },
    companyLabel() {
      return this.formData.userType === USER_TYPE.PARTNER_MANAGER
        ? '客户'
        : '供应商';
    },
    // 是否是系统管理员账号
    isSysAdminAccount() {
      return this.$store.state.userInfo.userType === USER_TYPE.SYS_MANAGER;
    },
    // 提交api
    submitApi() {
      if (this.isSysAdminAccount) {
        return this.formData.id ? userApi.editUser : userApi.addUser;
      }
      return this.formData.id
        ? userApi.editCompanyUser
        : userApi.addCompanyUser;
    },
  },
  watch: {
    // 监听用户类型变更，加载对应的客户/供应商数据
    'formData.userType': async function(value) {
      // 客户
      if (value === USER_TYPE.PARTNER_MANAGER) {
        if (this.partnerList.length) {
          this.companyOptions = this.partnerList.slice(0);
          return;
        }
        const res = await customerApi.getPartnerList({
          pageSize: 1000,
          customerStatus: 'NORMAL',
        });
        this.partnerList = res.data.records;
        this.companyOptions = res.data.records;
        this.deptOptions = [];
        return;
      }
      // 供应商
      if (value === USER_TYPE.SUPPLIER_MANAGER) {
        if (this.supplierList.length) {
          this.companyOptions = this.supplierList.slice(0);
          return;
        }
        const res = await supplierApi.getSupplierList({
          pageSize: 1000,
          onState: '1',
        });
        this.supplierList = res.data.records;
        this.companyOptions = res.data.records;
        this.deptOptions = [];
        return;
      }
      // 系统账号
      if (value === USER_TYPE.SYS_MANAGER) {
        this.deptOptions = [...this.deptList];
        return;
      }
      // value 为空时
      this.deptOptions = [];
    },
  },
  async created() {
    const deptRes = await deptApi.getDeptTree();
    if (deptRes.data) {
      this.deptList = formatTree([deptRes.data]);
      this.handleNodeClick({ id: '' });
    }
    const roleRes = await roleApi.getAllRoles({ pageSize: 1000 });
    if (roleRes.success) {
      this.roleList = roleRes.data.records;
    }
  },
  methods: {
    getUserListByPage: userApi.getUserListByPage,
    checkMobileIsUnique: userApi.checkMobileIsUnique,
    handleCreate() {
      // 根据当前账号类型，来创建新用户类型
      this.formData.userType = this.getLoginUserType;
      this.visible = true;
      this.$nextTick(() => {
        this.handleUserTypeChange(USER_TYPE.SYS_MANAGER);
      });
    },
    /**
     * 监听账户类型变更
     */
    handleUserTypeChange(value) {
      // 清除已选择的 客户/供应商和所属
      this.formData.companyId = '';
      this.formData.deptId = '';
      // 系统账户：设置默认值
      if (value === USER_TYPE.SYS_MANAGER) {
        this.formData.companyId = this.deptList[0].id;
        this.formData.companyName = this.deptList[0].name;
        this.formData.deptId = this.deptList[0].id;
      }
      // 清除校验
      this.$nextTick(() => {
        this.$refs['form'].clearValidate();
      });
    },
    // 监听客户/供应商变更
    async handleCompanyChange(companyId) {
      const res = await deptApi.getDeptTreeByCompanyId(companyId);
      if (res.data) this.deptOptions = formatTree([res.data]);
      // 当面客户/供应商 就是当前所属的第一层级部门
      this.$nextTick(() => {
        this.formData.deptId = companyId;
        const item = this.companyOptions.find(item => item.id === companyId);
        this.formData.companyName = item?.customerName || item.companyName;
      });
    },
    handleSearch() {
      this.$refs['pageTable']?.onLoad();
    },
    handleSelectionChange(selections) {
      this.selectionRows = selections;
    },
    handleNodeClick(node) {
      this.apiParams.deptId = node.id === this.apiParams.deptId ? '' : node.id;
      this.handleSearch();
    },
    handleCancel() {
      this.visible = false;
    },
    handleSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitApi(this.formData).then(res => {
            this.loading = false;
            if (res.success) {
              this.handleCancel();
              this.$message.success(this.formData.id ? '修改成功' : '添加成功');
              this.handleNodeClick({ id: '' });
            }
          }).finally(()=>{
            this.loading = false;
          });
        }
      });
    },
    handleClose() {
      this.$refs['form'].clearValidate();
      this.$refs['form'].resetFields();
      this.phone = '';
      this.formData = { ...initialForm };
    },
    handleEdit(scope) {
      this.phone = scope.row.mobile;
      assign(this.formData, scope.row);
      this.$nextTick(() => {
        this.visible = true;
        userApi.getUserRolesByUserId(scope.row.id).then(res => {
          if (res.success) {
            this.formData.roleIds = res.data;
          }
        });
      });
    },
    // 批量停用
    handleBatchChageStatus() {
      this.callApiChangeStatus(
        this.selectionRows.map(item => item.id),
        STATUS.STOP,
      ).then(res => {
        if (res.success) {
          this.selectionRows = [];
          this.$message.success('批量操作成功');
          this.$refs['pageTable'].onLoad();
        }
      });
    },
    handleChageStatus(scope, status = STATUS.STOP) {
      this.callApiChangeStatus([scope.row.id], status).then(res => {
        if (res.success) {
          this.$message.success('操作成功');
          this.$refs['pageTable'].onLoad();
        }
      });
    },
    callApiChangeStatus(ids, status) {
      const api =
        status === STATUS.STOP
          ? userApi.userDisableByIds
          : userApi.userEnableByIds;
      return api(ids);
    },
  },
};
</script>
<style lang="less" scoped>
.permissions_user__list {
  h1 {
    font-size: 16px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin: 20px 0 10px 0;
  }
  // 删除
  .status_0 {
    color: gray;
  }
  // 正常
  .status_1 {
    color: green;
  }
  // 停用
  .status_2 {
    color: red;
  }
}
</style>
